<template>
  <div id="smartTown">
    <a href="http://laidian360.mikecrm.com/OPfnwxH">
      <img
        src="@/assets/img/project_1.png"
        width="100%"
        height="100%"
      >
    </a>
  </div>
</template>
<script>
export default {
  name: 'SmartTown',
  data () {
    return {}
  }
}
</script>
<style scoped>
</style>
